import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  space: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['default', 'dark']),
  className: PropTypes.string,
};
const defaultProps = {
  height: undefined,
  width: undefined,
  space: null,
  size: 'md',
  variant: 'default',
  className: '',
};

function Placeholder({ width, height, size, variant, space, className }) {
  const wrapperClassName = classNames(className, {
    [`space-${space}`]: !!space,
  });

  return (
    <div className={wrapperClassName}>
      <div
        className={classNames(
          'placeholder-static',
          `placeholder-static--${size}`,
          `placeholder-static--${variant}`,
        )}
        style={{
          width,
          height,
        }}
      />
    </div>
  );
}

Placeholder.propTypes = propTypes;
Placeholder.defaultProps = defaultProps;

export default Placeholder;

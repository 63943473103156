import Placeholder from '@/storychief/components/Placeholder';

function PricingTablePlaceholder() {
  return (
    <table className="sc-pricing-table">
      <tbody>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder variant="dark" size="lg" />
          </td>
        </tr>
        <tr>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
          <td className="sc-pricing-table__td">
            <Placeholder size="lg" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PricingTablePlaceholder;

import { gql, useQuery } from '@apollo/client';
import useEntitlements from '@/entitlements/hooks/useEntitlements';

const UPGRADE_PATH_FOR_ENTITLEMENT_QUERY = gql`
  query EntitlementTeaserUpgradePathForEntitlement($input: UpgradePathForEntitlementInput!) {
    upgradePathForEntitlement(input: $input) {
      __typename
      amount
      prices {
        __typename
        stripe_price
        primary
        entitlements
        interval
      }
      payload {
        __typename
        stripe_price
        primary
        quantity
        primary
      }
    }
  }
`;

export default function useUpgradePathForEntitlement(entitlement?: string) {
  const getEntitlement = useEntitlements();
  const isEntitled = getEntitlement(entitlement);

  return useQuery(UPGRADE_PATH_FOR_ENTITLEMENT_QUERY, {
    variables: {
      input: {
        entitlement,
      },
    },
    skip: !entitlement || isEntitled === true,
  });
}

import PropTypes from 'prop-types';
import StoryChief from '@/storychief';
import Button from '@/storychief/components/Button';
import PricingTablePlaceholder from '@/pricing/components/PricingTablePlaceholder';
import analyticsTrack from '@/storychief/utils/analyticsTrack';

const propTypes = {
  entitlement: PropTypes.string.isRequired,
};
const defaultProps = {};

function PricingCardPlaceholder({ entitlement }) {
  function handleOnContactUs() {
    analyticsTrack('Upgrade Intent', { entitlement, upgradeIntentType: 'contact' });

    StoryChief.startIntercomMessage();
  }

  return (
    <div className="sc-pricing-card">
      <div className="sc-pricing-card-header">
        <div className="sc-pricing-card-header__main">
          <h3 className="sc-pricing-card__name">Not Available</h3>
          <div className="sc-pricing-card__description">Upgrade to use this feature</div>
        </div>
        <div className="sc-pricing-card-header__cta">
          <Button onClick={handleOnContactUs}>Contact us</Button>
        </div>
      </div>
      <div className="sc-pricing-card__body">
        <PricingTablePlaceholder />
      </div>
    </div>
  );
}

PricingCardPlaceholder.propTypes = propTypes;
PricingCardPlaceholder.defaultProps = defaultProps;

export default PricingCardPlaceholder;
